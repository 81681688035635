<template>
  <div>
    <b-form>
      <b-row class="mb-1">
        <b-col>
          <h2>
            Listado de Eventos
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <b-form-group label="Email Productor Carbono" label-for="basicInput">
            <b-form-input
              v-model="email"
              placeholder="Digite Email"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <b-form-group label="Código de la Finca" label-for="basicInput">
            <b-form-input
              v-model="farmCode"
              placeholder="Digite Código"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4" xl="4" class="mt-2">
          <b-row
            ><b-button class="mx-1" variant="primary" @click="getAllCarbonEvents">
              <feather-icon icon="SearchIcon" />Filtrar
            </b-button>
            <b-button variant="primary" @click="removeFilter">
              <feather-icon />Borrar Filtro
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>

    <b-card-code
      :title="
        'Lista de eventos recientes (Cantidad: ' + carbonEvents.length + ')'
      "
      no-body
    >
      <b-table
        ref="selectableTable"
        :fields="fields"
        :items="carbonEvents"
        responsive
        selectable
        class="mb-0"
        :select-mode="selectMode"
        @row-selected="onRowSelected"
      >
        <template #cell(line)="data">
          {{ data.item.line }}
        </template>

        <template #cell(farmName)="data">
          {{ data.item.farmName }}
        </template>

        <template #cell(email)="data">
          {{ data.item.email }}
        </template>

        <template #cell(farmCode)="data">
          {{ data.item.farmCode }}
        </template>

        <template #cell(firstName)="data">
          {{ data.item.firstName }}
        </template>

        <template #cell(lastName)="data">
          {{ data.item.lastName }}
        </template>

        <template #cell(eventType)="data">
          {{ data.item.eventType }}
        </template>

        <template #cell(description)="data">
          {{ data.item.description }}
        </template>

        <template #cell(createDate)="data">
          <div>{{ transformDate(data.item.createDate) }}</div>
        </template>
      </b-table>
    </b-card-code>
    <b-row
      v-if="this.carbonEvents.length <= 0"
      class="justify-content-center mt-3"
      style="text-align:center"
    >
      <b-col style="justify-content:center" xl="4" md="6" lg="4">
        <b-alert variant="primary" show>
          <div class="alert-body">
            <span><strong>No Hay Información para mostrar</strong></span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-modal
      centered
      ref="information-event-modal"
      hide-footer
      title="INFORMACIÓN DEL EVENTO"
      @hidden="resetModal"
    >
      <b-row>
        <b-col
          ><h6 class="mt-1">Tipo Evento</h6>
          <p>{{ eventSelected.eventType }}</p>
          <h6 class="mt-1">Fecha Creación</h6>
          <p>{{ transformDate(eventSelected.createDate) }}</p>
          <h6 class="mt-1">Codigo Finca</h6>
          <p>{{ eventSelected.farmCode }}</p>
          <h6 class="mt-1">Descripción Evento</h6>
          <p>{{ eventSelected.description }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          ><p v-if="this.eventsFiles != null">Archivos: {{ eventsFiles }}</p>
          <p v-else>No Tiene archivos cargados</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            class="mt-2"
            variant="outline-primary"
            block
            @click="resetModal"
          >
            <span class="align-middle">Cerrar</span>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-if="this.viewFiles"
            class="mt-2"
            variant="primary"
            block
            @click="downloadZipFile(eventSelected)"
          >
            <span class="align-middle">Descargar Archivos</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink,
  BButton,
  BImg,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BTable,
  BAlert,
  BBadge,
  BFormFile,
  BForm,
  BFormSelect,
  BFormCheckbox,
  BCardText,
} from "bootstrap-vue";
import BCardCode from "@/@core/components/b-card-code/BCardCode.vue";
import Cleave from "vue-cleave-component";
import departamentos from "../../assets/departamentos-municipios.json";
import axios from "@axios";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BAlert,
    BLink,
    BButton,
    BCardText,
    BImg,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormFile,
    BTable,
    BCardCode,
    BFormSelect,
    BFormCheckbox,
    Cleave,
  },
  data() {
    return {
      axiosIns: axiosIns,
      selectMode: "single",
      fields: [
        { key: "line", label: "#" },
        { key: "createDate", label: "Fecha Creación" },
        { key: "eventType", label: "Evento" },
        { key: "email", label: "Email" },
        { key: "farmCode", label: "Cod. Finca" },
        { key: "farmName", label: "Finca" },
        { key: "firstName", label: "Nombres" },
        { key: "lastName", label: "Apellidos" },
      ],
      carbonEvents: [{}],
      email: null,
      farmCode: null,
      eventSelected: {},
      carbonFarmInformation: {},
      event: {},
      viewFiles: false,
      eventsFiles: null,
    };
  },
  created() {
    this.getAllCarbonEvents();
  },
  methods: {
    getAllCarbonEvents() {
      axios
        .get("/CarbonFarm/GetAllCarbonTopFarmEvents", {
          params: {
            email: this.email,
            farmCode: this.farmCode,
          },
        })
        .then((response) => {
          this.carbonEvents = response.data;
          this.carbonEvents.forEach((item) => {
            item.line = this.carbonEvents.indexOf(item) + 1;
          });
        });
    },
    removeFilter() {
      axios
        .get("/CarbonFarm/GetAllCarbonTopFarmEvents", {
          params: {
            email: null,
            farmCode: null,
          },
        })
        .then((response) => {
          this.carbonEvents = response.data;
          this.carbonEvents.forEach((item) => {
            item.line = this.carbonEvents.indexOf(item) + 1;
            this.email = null;
            this.farmCode = null;
          });
        });
    },

    onRowSelected(items) {
      this.eventSelected = items[0];
      this.eventSelected = items[0];
      this.getEventFiles(this.eventSelected);
      if (this.eventSelected) this.$refs["information-event-modal"].show();
    },

    downloadZipFile(item) {
      this.$refs["information-event-modal"].hide();
      window.open(
        this.axiosIns.sostyApiUrl +
          "CarbonFarm/DownloadFilesCarbonFarm?carbonFarmEventID=" +
          item.carbonFarmEventID,
        "blank"
      );
    },

    getEventFiles(item) {
      var count = 0;
      axios
        .get("/CarbonFarm/GetCarbonFarmEventByID", {
          params: { carbonFarmEventID: item.carbonFarmEventID },
        })
        .then((response) => {
          this.event = response.data;
          if (this.event.eventFile1Url != null) {
            count++;
          }
          if (this.event.eventFile2Url != null) {
            count++;
          }
          if (this.event.eventFile3Url != null) {
            count++;
          }
          if (this.event.eventVideo1Url != null) {
            count++;
          }
          if (this.event.eventVideo2Url != null) {
            count++;
          }
          if (this.event.eventVideo3Url != null) {
            count++;
          }
          if (this.event.picture1Url != null) {
            count++;
          }
          if (this.event.picture2Url != null) {
            count++;
          }
          if (this.event.picture3Url != null) {
            count++;
          }
          if (this.event.picture4Url != null) {
            count++;
          }
          if (this.event.picture5Url != null) {
            count++;
          }
          if (count > 0) {
            this.viewFiles = true;
            this.eventsFiles = count;
          }
        });
    },

    resetModal() {
      this.eventsFiles = null;
      this.viewFiles = false;
      this.$refs["information-event-modal"].hide();
    },

    transformDate(date) {
      if (date == null) {
        return "--/--/----";
      } else {
        return date.substring(0, 10);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
